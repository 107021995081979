<template>
    <div id="page-branch-list">
        <div class="vx-row">
            <div class="vx-col md:w-full flex items-center mb-5">
                <TitleScreen></TitleScreen>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-auto" style="display: inline-grid">
                <label class="vs-input--label">{{ $t('minioStorage.dateRange') }}</label>
                <date-range-picker
                    ref="picker"
                    :locale-data="{format: 'dd/mm/yyyy' }"
                    :opens="'right'"
                    @update="initScreen"
                    v-model="dateRange">
                </date-range-picker>
            </div>
            <div class="vx-col sm:w-1/4 md:w-1/4 lg:w-1/4 w-full">
                <label class="vs-input--label">Camera</label>
                <v-select class=""
                          v-model="cameraModel"
                          :clearable="false"
                          :options="cameraOptions"
                          data-vv-validate-on="blur">
                    <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
            </div>
            <div class="vx-col sm:w-auto">
                <vs-button icon-pack="feather" @click="initScreen" class="ml-auto mt-6">{{ $t('button.search') }}</vs-button>
            </div>
        </div>
        <div class="vx-row mt-4">
            <div class="vx-col w-full md:w-2/12">
                <div class="vx-card p-4 w-full">
                    <div class="w-full">
                        <span class="font-bold">{{ $t('minioStorage.totalFile') }}</span>
                    </div>
                    <div class="w-full">
                        <span class="count-day">{{ countDays }} {{ $t('minioStorage.day') }}</span>
                    </div>
                    <div class="w-full flex">
                        <div class="truncate md:w-9/12 w-full text-left mt-4">
                            <div class="component w-full">
                                <span class="mb-1 font-bold count-data">{{ countFile}}</span>
                            </div>
                        </div>
                        <div class="vx-col md:w-3/12 w-full">
                            <feather-icon icon="FileIcon" class="mt-2 p-3 inline-flex rounded-full bg-primary text-white"></feather-icon>
                        </div>
                    </div>
                </div>

                <div class="vx-card p-4 w-full mt-4">
                    <div class="w-full">
                        <span class="font-bold">{{ $t('minioStorage.totalFileError') }}</span>
                    </div>
                     <div class="w-full">
                        <span class="count-day">{{ countDays}} {{ $t('minioStorage.day') }}</span>
                    </div>
                    <div class="w-full flex">
                        <div class="truncate md:w-9/12 w-full text-left mt-4">
                            <div class="component w-full">
                                <span class="mb-1 font-bold count-data">{{ countFileError }}</span>
                            </div>
                        </div>
                        <div class="vx-col md:w-3/12 w-full">
                            <feather-icon icon="FileIcon" class="mt-2 p-3 inline-flex rounded-full bg-warning text-white"></feather-icon>
                        </div>
                    </div>
                </div>

                 <div class="vx-card p-4 w-full mt-4">
                    <div class="w-full">
                        <span class="font-bold">{{ $t('minioStorage.totalStorage') }}</span>
                    </div>
                     <div class="w-full">
                        <span class="count-day">{{ countDays }} {{ $t('minioStorage.day') }}</span>
                    </div>
                    <div class="w-full flex">
                        <div class="truncate md:w-9/12 w-full text-left mt-4">
                            <div class="component w-full">
                                <span class="mb-1 font-bold count-data">{{ storageFile }}</span>
                            </div>
                        </div>
                        <div class="vx-col md:w-3/12 w-full">
                            <feather-icon icon="DatabaseIcon" class="mt-2 p-3 inline-flex rounded-full bg-success text-white"></feather-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vx-col md:w-10/12 w-full">
                <div class="vx-card w-full p-4 h-full">
                    <div class="w-full">
                        <span class="font-bold">{{ $t('minioStorage.storage') }}</span>
                    </div>
                     <div class="w-full">
                        <span class="count-day">{{ countDays }} {{ $t('minioStorage.day') }}</span>
                    </div>
                     <div id="aricleChart">
                        <vue-apex-charts
                            v-if="showDataMinioChart"
                            type="line"
                            height="300"
                            :options="minioChart.chartOptions"
                            :series="minioChart.series">
                        </vue-apex-charts>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full mt-4">
            <minio-storage-data :minioStorageData="minioStorageData" :key="`table_with_page_${minioStorageData.length}`" :infoOrganization="infoOrganization" :total-data="this.storageFile"/>
        </div>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'
import moment from 'moment';
import MinioStorageData from './MinioStorageData.vue'
import vSelect from 'vue-select'
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        VueApexCharts,
        DateRangePicker,
        MinioStorageData,
        vSelect,
        TitleScreen
    },
    data(){
        return {
            aliasId: null,

            //data
            infoOrganization: {},
            minioStorageData: [],
            cameraOptions: [],

            countDays: 0,
            storageFile: 0,
            countFile: 0,
            countFileError: 0,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                let date = new Date();
                date.setDate(date.getDate() - 6);
                date.setHours(0, 0, 0);
                return date;
                })()
            },
            showDataMinioChart: false,
            minioChart: {
                series: [],
                chartOptions: {
                chart: {
                    height: 350,
                    type: "line",
                    zoom: {
                    enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                // title: {
                // text: 'Product Trends by Month',
                // align: 'left'
                // },
                grid: {
                    row: {
                    colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                    opacity: 0.5
                    }
                },
                xaxis: {
                    labels: {
                    showDuplicates: false,
                    formatter: function(val) {
                        if (!val) return val;
                        let dateSplit = val.split("-"),
                        type = dateSplit[1],
                        dateTemp = moment(dateSplit[0], "YYYYMMDD");
                        if (type === "W") return `Tuần ${dateTemp.isoWeek()}`;

                        if (type === "M")
                        return ` ${dateTemp.format("MM")}/${dateTemp.year()}`;

                        return val.slice(6, 8) + "/" + val.slice(4, 6);
                    },
                    style: {
                        cssClass: "text-grey fill-current"
                    }
                    },
                    axisTicks: {
                        show: false
                    },
                    categories: [],
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                    style: {
                        cssClass: "text-grey fill-current"
                    },
                    formatter: function(val) {
                        return formatBytes(val);
                        // if (Number(val) === val && val % 1 !== 0) {
                        // return kFormatter(val).toFixed(1);
                        // } else {
                        // return kFormatter(val);
                        // }
                    }
                    },
                    grid: {
                        show: false
                    }
                },
                legend: {
                    position: "top",
                    show: true,
                    markers: {
                    fillColors: ["#007AFE", "#FEC400", "#F12B2C"]
                    }
                },
                colors: ["#007AFE", "#FEC400", "#F12B2C"],
                fill: {
                    type: "gradient",
                    gradient: {
                    shade: "dark",
                    inverseColors: false,
                    gradientToColors: ["#007AFE", "#FEC400", "#F12B2C"],
                    shadeIntensity: 0.5,
                    type: "horizontal",
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                    }
                },
                stroke: {
                    curve: "smooth",
                    // dashArray: [0, 8],
                    width: [
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2,
                    2
                    ]
                }
            }
        },
        }
    },
    computed: {
        cameraModel: {
            get() {
                let object = this.cameraOptions.find(el => el.value == this.aliasId);
                if (!object) return;
                return {
                    label: object.label,
                    value: this.aliasId
                }
            },
            set(object) {
                this.aliasId = object ? object.value : null;
            }
        }
    },
    methods: {
        getInfoOrganization: function(){
            this.$oauth.post('/organization/info', {}).then((response) => {
                this.infoOrganization = response.data;
                this.infoOrganization.from = this.dateRange.startDate;
                this.infoOrganization.to = this.dateRange.endDate;
                this.infoOrganization.fullAddress = response.data.address.fullAddress;
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        getCamera() {
            return new Promise(resolve => {
                let url = '/camera/all';
                this.$vs.loading();
                this.$crm.post(url).then((response) => {
                    this.cameraOptions = response.data.map(el => {
                        return {
                            label: el.cameraName,
                            value: el.aliasId
                        }
                    })
                    this.cameraOptions.unshift({value: null, label: this.$t('minioStorage.all')})
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        countDateRange: function(fromDate, toDate) {
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(fromDate);
            const secondDate = new Date(toDate);
            const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
            this.countDays = diffDays;
        },
        optimizedChart: function(data) {
            data = data.sort(function(a, b) {
                return parseInt(a.stringDate) - parseInt(b.stringDate);
            });
            data.map(item => {
                let date = moment(item.stringDate, "YYYYMMDD");
                item.month = date.format("Y") + date.format("M");
                item.week = date.isoWeek();
                item.day = date.format("D");
                return item;
            });

            let result = [];
            data.reduce(function(res, value) {
                if (data.length <= 21) {
                if (!res[value.day]) {
                    res[value.day] = {
                    day: value.day,
                    stringDate: value.stringDate,
                    totalData: 0
                    };
                    result.push(res[value.day]);
                }
                res[value.day].totalData += value.totalData;
                res[value.day].stringDate += "-D";
                return res;
                }
                if (14 < data.length && data.length < 62) {
                if (!res[value.week]) {
                    res[value.week] = {
                    week: value.week,
                    stringDate: value.stringDate,
                    totalData: 0
                    };
                    result.push(res[value.week]);
                }
                res[value.week].totalData += value.totalData;
                res[value.week].stringDate += "-W";
                return res;
                } else if (data.length > 62) {
                if (!res[value.month]) {
                    res[value.month] = {
                    month: value.month,
                    stringDate: value.stringDate,
                    totalData: 0
                    };
                    result.push(res[value.month]);
                }
                res[value.month].totalData += value.totalData;
                res[value.month].stringDate += "-M";
                return res;
                }
            }, {});
            data = result;
            return data;
        },
        getDataMinioChart: function(payload){
            this.countDateRange(this.dateRange.startDate, this.dateRange.endDate);
            this.$processor.post('/minio-storage/find-by-condition', payload).then((response) => {
                response.data.forEach(el => {
                    el.stringDate = el.currentDate;
                    el.totalData = el.storage;
                });
                let result = this.optimizedChart(response.data);
                result = result.filter(el => el);
                this.minioChart.series = [];
                this.countFile = response.data.reduce((result, el) => result + parseFloat(el.file), 0);
                this.countFileError = response.data.reduce((result, el) => result + parseFloat(el.fileError), 0);
                this.storageFile = formatBytes(response.data.reduce((result, el) => result + parseFloat(el.storage), 0));
                let storage = {
                    name: this.$t('minioStorage.storage'),
                    data: result.map(el => el.totalData)
                }
                this.minioChart.series.push(storage)
                this.minioChart.chartOptions.xaxis.categories = [];
                this.minioChart.chartOptions.xaxis.categories.push(
                    ...result.map(el => el.stringDate)
                )
                this.showDataMinioChart = true;

            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        getStorageFileDetail: function(payload){
            this.$processor.post('/minio-storage/storage-file', payload).then((response) => {
                this.minioStorageData = response.data;
                if (this.minioStorageData.length > 0) {
                     this.minioStorageData = this.minioStorageData.map(el => {
                         this.cameraOptions.forEach(camera => {
                             if (el.deviceId == camera.value) el.cameraName = camera.label;
                         })
                         el.size = formatBytes(el.size);
                         if (el.createdDate) el.createdDate = moment(el.createdDate).format("DD/MM/YYYY");
                         return el;
                     })
                }
            }).catch((err) => {
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        initScreen(){
            this.showDataMinioChart = false;
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
            const payload = {
                fromDate: this.dateRange.startDate,
                toDate: this.dateRange.endDate,
                aliasId: this.aliasId
            }
            this.getDataMinioChart(payload);
            this.getStorageFileDetail(payload);
            this.getInfoOrganization();
        },
        searchData() {

        }
    },
    async created(){
        await this.getCamera();
        this.initScreen();
    }
}

function formatBytes(bytes, decimals = 2) {
    if (bytes == 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
</script>

<style lang="scss">
.count-data {
    font-size: 28px;
}
.count-day {
    color: #626262;
}
</style>
